import React, { useRef } from "react";
import forecastsHeaderImg from "../images/forecasts-header-img.jpg";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { animated, useSpring } from "react-spring";
import Footer from "../components/Footer";
import TopNavBar from "../components/TopNavBar";
import scrollArrow from "../images/icons/scroll-arrow.svg";
import aiImage from "../images/ai-icon.png";
import forcastBG from "../images/forcast-background.jpg";
import Helmet from "react-helmet";
import favicon from "../images/icons/favicon.png";

const Forecasts = () => {
  const breakpoints = useBreakpoint();

  const scrollBtnRef = useRef();

  const handleBackClick = () => {
    scrollBtnRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Animations
  const fadeInSlideUp = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 120 },
    delay: 800,
  });

  //Styles
  const ForecastsPageStyles = {
    color: "white",
    height: "100%",
    fontFamily: "League Spartan, Roboto, sans-serif, serif",
    zIndex: 1,
    padding: 0,
    margin: 0,
    letterSpacing: 0.66,
    overflow: "hidden",
    position: "relative",
  };

  const ForecastsHead = {
    backgroundImage: `url(${forecastsHeaderImg})`,
    backgroundSize: "cover",
    height: breakpoints.sm ? 650 : 725,
    backgroundRepeat: "repeat-y",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    zIndex: -1,
  };

  const ScrollArrow = {
    position: "absolute",
    top: "65%",
    left: "0",
    right: "0",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 100,
  };

  const AiForecasts = {
    height: breakpoints.sm ? 650 : 675,
    display: "flex",
    flexDirection:  breakpoints.sm ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -206,
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 65%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%)",
    padding: breakpoints.sm ? "286px 16px 16px 16px" : "104px 0 0 0",
  };

  const SectionTitle = {
    color: "#FF9800",
    fontSize: 15,
  };

  const SectionExplanationTitle = {
    fontSize: 30,
    lineHeight: 1.3,
    fontWeight: 600,
    letterSpacing: 0.78,
    width:  breakpoints.sm ? "100%" : 438,
  };

  const SectionText = {
    fontWeight: 500,
    letterSpacing: 0,
    width:  breakpoints.sm  ? "100%" : 438,
    lineHeight: 1.7,
    fontSize: 15
  };

  const ForecastsExplanations = {
    backgroundImage: `url(${forcastBG})`,
    backgroundMinSize: 1940,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    display: "flex",
    flexDirection:  breakpoints.sm  ? "column": "row",
    justifyContent: "center",
    padding: "86px 16px 16px 16px",
  };

  const SectionBlock = {
    padding:  breakpoints.sm ? 16: 60,
  };

  const ForecastInfo = {
    position: "absolute",
    top: 300,
    right: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    height: 600,
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div style={ForecastsPageStyles}>
      <Helmet>
        <title>Thunderstock</title>
        <link rel="canonical" href="http://thunderstock.nl" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <TopNavBar />

      <div style={ForecastsHead}></div>
      <div style={ForecastInfo}>
        <h2
          style={{
            width: breakpoints.sm ? "100%" : 500,
            fontSize: 34,
            textAlign: "center",
          }}
        >
          Artificial intelligence powers our accurate demand forecasts
        </h2>

        <div style={ScrollArrow}>
          <animated.div style={fadeInSlideUp}>
            <img src={scrollArrow} onClick={handleBackClick} alt="arrow" />
          </animated.div>
        </div>
      </div>

      <div style={AiForecasts} ref={scrollBtnRef}>
        <div>
          <h3 style={SectionTitle}>Forecasts</h3>

          <h2 style={SectionExplanationTitle}>Superior demand forecast</h2>

          <p style={SectionText}>
            Our AI-based demand forecasting engine uses machine learning to
            accurately predict future demand. This allows us to incorporate many
            features which drive your demand and therefore make superior demand
            forecast.
          </p>
        </div>

        <div>
          <img
            src={aiImage}
            alt="module blocks"
            style={{ width:  breakpoints.sm ? 252 : 352, marginLeft:  breakpoints.sm  ? 0 : 225, paddingTop:  breakpoints.sm ? 56 : 0 }}
          />
        </div>
      </div>

      <div style={ForecastsExplanations}>
        <div>
          <div style={SectionBlock}>
            <h3 style={SectionTitle}>Machine learning</h3>

            <p style={SectionText}>
              Machine learning is a branch of artificial intelligence aimed at
              uncovering complex patterns in large amounts of data. Due to the
              complex nature of retail, involving many channels and skus,
              machine learning is a perfect fit for predicting future demand.
            </p>
          </div>

          <div style={SectionBlock}>
            <h3 style={SectionTitle}>Features</h3>

            <p style={SectionText}>
              Besides your own product and store features, our engine uses
              additional information which impacts your retail demand, such as
              geographic location, weather, holidays, events and much more. Our
              models are tailored specifically to your customer demand.
            </p>
          </div>
        </div>
        <div style={SectionBlock}>
          <h3 style={SectionTitle}>Adaptive</h3>

          <p style={SectionText}>
            Due to the trendiness of fashion retail, it is impossible to
            perfectly predict the latest fashion trends, therefore we deploy an
            additional adaptive layer in our model to respond to real customer
            demand, allowing you to adapt quickly to the newest (un-)trends.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Forecasts;
